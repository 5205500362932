import React, { useEffect } from "react";
import Head from "next/head";
import Image from "next/image";
import { useRating } from "@/hooks";
import { useStore, clearStore } from "@/store";
import { clearLocalStorage, clearToken, setSalonLocalStorage } from "@/utils";
import { useRouter } from "next/router";

export default function WaitScreen() {
  // useRating();
  const [state, dispatch] = useStore();
  const { user } = state;
  const salon = user?.salon;
  const router = useRouter();

  useEffect(() => {
    setSalonLocalStorage(salon!);
  }, [user, state]);

  const currentSalonBranchAddress = typeof window !== 'undefined' ?
    localStorage.getItem("currentSalonBranchAddress") : salon?.address;

  const handleLogOut = () => {
    clearToken();
    dispatch(clearStore());
    clearLocalStorage();
    location.reload();
  };

  return (
    <>
      <Head>
        <title>chờ đánh giá</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="shortcut icon" href="/statics/Logo-easysalon.png" />
      </Head>
      <div>
        <div>
          <h5
            className="mt-[35px] ml-[35px] cursor-pointer text-[#808B9F]"
            onClick={handleLogOut}
          >
            Đăng xuất
          </h5>
        </div>
        <div className="flex flex-col items-center mt-[60px]">
          {salon?.photo?.url && (
            <Image
              src={salon?.photo?.url}
              alt="salon-img"
              width={300}
              height={300}
            />
          )}
          <h1 className="mt-[34px]">{salon?.name}</h1>
          <h3 className="mt-[12px] font-normal">{currentSalonBranchAddress}</h3>
        </div>
      </div>
    </>
  );
}
